import { Box, Heading, VStack } from '@chakra-ui/react';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, {
  FC,
  PropsWithChildren,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import ReactDOM from 'react-dom';
import { usePopper } from 'react-popper';
import { useMedia } from 'react-use';

import { AnimateHeight } from '@app/components/AnimateHeight';
import { Icon } from '@app/components/Icon';
import { useOnClickOutside } from '@app/hooks/other/useOnClickOutside';

import s from './AppHeaderMenu.module.scss';

interface Props extends PropsWithChildren {
  items: { title: string; link: string }[];
  title: string;
}

const variants = {
  open: {
    opacity: 1,
    height: 'auto'
  },
  collapsed: { opacity: 0, height: 0 }
};

export const AppHeaderMenu: FC<Props> = ({ title, items, children }) => {
  const isMobile = useMedia('(max-width: 960px)', false);
  const ref = useRef<Element | null>(null);
  const [mounted, setMounted] = useState(false);
  const router = useRouter();

  const isActive = useMemo(() => {
    return items.some(item => router.pathname.includes(item.link));
  }, [items, router.pathname]);

  const [open, setOpen] = useState(false);
  const [popperElement, setPopperElement] = useState<HTMLElement>();
  const [referenceElement, setReferenceElement] = useState<HTMLElement>();

  useEffect(() => {
    ref.current = document.querySelector<HTMLElement>('#portal');
    setMounted(true);
  }, []);

  useOnClickOutside(
    [
      { current: popperElement } as React.RefObject<HTMLElement>,
      { current: referenceElement } as React.RefObject<HTMLElement>
    ],
    () => setOpen(false)
  );

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    strategy: 'fixed',
    placement: 'bottom-start',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 7]
        }
      }
    ]
  });

  function renderContent() {
    return items.map(item => (
      <Link
        key={item.title}
        href={item.link}
        className={clsx(s.link)}
        onClick={() => setOpen(false)}
      >
        {item.title}
      </Link>
    ));
  }

  function renderMobileContent() {
    return items.map(item => (
      <Link
        key={item.title}
        href={item.link}
        className={clsx(s.link, s.mobileLink, {
          [s.active]: router.pathname.includes(item.link)
        })}
      >
        {item.title}
      </Link>
    ));
  }

  function getDropdown() {
    return (
      <Box
        ref={setPopperElement as React.LegacyRef<HTMLDivElement>}
        style={styles.popper}
        {...attributes.popper}
        className={s.dropdown}
      >
        <motion.div
          transition={{ delay: 0, duration: 0.2 }}
          initial={{ opacity: 0, transform: 'translateY(10px)' }}
          animate={{ opacity: 1, transform: 'translateY(0px)' }}
          exit={{
            opacity: 0
          }}
        >
          <VStack
            alignItems="flex-start"
            // gap={3}
            sx={{
              padding: '12px 12px 24px',
              background: 'gray.13',
              borderRadius: '8px',
              minWidth: '180px',
              backdropFilter: 'blur(3px)'
            }}
          >
            {renderContent()}
            {children}
          </VStack>
        </motion.div>
      </Box>
    );
  }

  if (!items.length) {
    return null;
  }

  return (
    <>
      <Heading
        as="h3"
        px="10px"
        py="8px"
        bg={isActive ? 'whiteOpacity.4' : 'transparent'}
        borderRadius={[0, '10px']}
        border="1px solid transparent"
        borderColor={isActive ? 'whiteOpacity.6' : 'transparent'}
        minWidth="92px"
        fontSize="16px"
        textAlign="center"
        transition="all 0.2s ease"
        _hover={{ color: 'white' }}
        cursor="pointer"
        fontWeight="400"
        color={isActive ? 'orange' : 'whiteOpacity.50'}
        ref={setReferenceElement as React.LegacyRef<HTMLHeadingElement>}
        onClick={() => setOpen(!open)}
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        {title} <Icon name="chevronDown" className={s.icon} />
      </Heading>
      {isMobile ? (
        <>
          <AnimateHeight
            ease="easeOut"
            variants={variants}
            duration={0.3}
            className={clsx(s.right, {
              [s.isOpen]: open
            })}
            isVisible={open}
          >
            {renderMobileContent()}
          </AnimateHeight>
        </>
      ) : (
        mounted &&
        ref.current &&
        ReactDOM.createPortal(
          <AnimatePresence>{open && getDropdown()}</AnimatePresence>,
          ref.current
        )
      )}
    </>
  );
};

import Tippy, { TippyProps } from '@tippyjs/react';
import cn from 'clsx';
import React, { ReactElement } from 'react';
import { Instance } from 'tippy.js';

import s from './Tooltip.module.scss';
import { useMedia } from 'react-use';

export interface TooltipProp extends TippyProps {
  children: React.ReactElement<any>;
  tooltip: ReactElement | string;
  autoCloseTimeout?: number;
  className?: string;
  triggerClassName?: string;
  disabled?: boolean;
}

const Tooltip: React.FC<TooltipProp> = ({
  children,
  tooltip,
  autoCloseTimeout,
  className,
  triggerClassName,
  disabled,
  ...rest
}) => {
  const isMobile = useMedia('(max-width: 960px)', false);
  const handleAutClose = (tippy: Instance) => {
    if (autoCloseTimeout) {
      setTimeout(() => tippy.hide(), autoCloseTimeout);
    }
  };

  return (
    <Tippy
      content={tooltip}
      zIndex={100000}
      arrow={false}
      {...rest}
      animation="shift-away"
      delay={100}
      className={cn(s.root, className)}
      onShow={handleAutClose}
      disabled={isMobile || disabled}
    >
      <div className={cn(s.trigger, triggerClassName)}>{children}</div>
    </Tippy>
  );
};

export default Tooltip;

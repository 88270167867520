import { Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/menu';
import { Box, Button, HStack, VStack, Text } from '@chakra-ui/react';
import Image from 'next/image';
import {
  useAddress,
  useChain,
  useDisconnect,
  useSwitchChain,
  useWalletConfig
} from '@thirdweb-dev/react';
import { AnimatePresence, motion } from 'framer-motion';
import Link from 'next/link';
import React, { useRef } from 'react';
import { useMedia, useToggle } from 'react-use';

import { CopyButton } from '@app/components/CopyButton';
import { Icon } from '@app/components/Icon';
import { formatWalletAddress } from '@app/helpers/format';
import { getActiveChain } from '@app/constants/chains';
import { useOnClickOutside } from '@app/hooks/other/useOnClickOutside';
import { TransactionHistorySelector } from '@app/components/TransactionsHistory/TransactionHistorySelector/TransactionHistorySelector';
import { useNetworkMismatch } from '@app/hooks/thirdweb/useNetworkMismatch';

const activeChain = getActiveChain();

export const ConnectedWallet = () => {
  const [showTxHistory, toggleShowTxHistory] = useToggle(false);
  const disconnect = useDisconnect();
  const address = useAddress();
  const chain = useChain();
  const isMobile = useMedia('(max-width: 960px)', true);
  const isMismatchedNetwork = useNetworkMismatch();
  const switchChain = useSwitchChain();

  const menuRef = useRef<HTMLDivElement>(null);

  useOnClickOutside([menuRef], () => toggleShowTxHistory(false));

  const walletConfig = useWalletConfig();

  const walletLogo = walletConfig?.meta.iconURL;

  const explorer = chain?.explorers ? chain?.explorers[0] : null;

  if (!address) {
    return null;
  }

  return (
    <>
      <Menu placement="bottom-end">
        <MenuButton
          as={Button}
          sx={{
            padding: '8px',
            background: 'gray.11',
            borderRadius: '8px',
            width: '100%'
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            {walletLogo && (
              <Image
                src={walletLogo}
                height={24}
                width={24}
                alt="Wallet logo"
              />
            )}
            {!isMobile && formatWalletAddress(address)}
          </Box>
        </MenuButton>
        <MenuList
          sx={{
            backgroundColor: 'gray.12',
            borderRadius: '8px',
            minWidth: '300px',
            overflow: 'hidden'
          }}
        >
          <MenuItem
            minH="48px"
            p="12px"
            gap="12px"
            borderBottom="1px solid"
            borderColor="whiteOpacity.12"
          >
            <HStack width="100%">
              <Box
                sx={{
                  width: '20px',
                  height: '20px',
                  borderRadius: '50%',
                  backgroundColor: 'success'
                }}
              />
              <VStack gap={0} alignItems="flex-start">
                <Text color="white" fontSize={14}>
                  {formatWalletAddress(address)}
                </Text>
                <Text color="whiteOpacity.50" fontSize={14}>
                  {chain?.name}
                </Text>
              </VStack>
              <HStack sx={{ marginLeft: 'auto' }}>
                <Link
                  onClick={e => e.stopPropagation()}
                  href={`${explorer?.url}/address/${address}`}
                  target="_blank"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <Icon
                    name="externalLink"
                    style={{ width: '20px', height: '20px' }}
                  />
                </Link>
                <CopyButton color="whiteOpacity.50" text={address} />
              </HStack>
            </HStack>
          </MenuItem>
          {isMismatchedNetwork && (
            <MenuItem
              minH="48px"
              p="12px"
              gap="8px"
              borderBottom="1px solid"
              borderColor="whiteOpacity.12"
              onClick={async () => {
                await switchChain(activeChain.chainId);
              }}
            >
              <HStack width="100%" alignItems="center">
                <Icon
                  name="compass"
                  style={{ width: '20px', height: '20px' }}
                />
                <Text color="whiteOpacity.50" fontSize={14}>
                  Switch network
                </Text>
              </HStack>
            </MenuItem>
          )}
          <MenuItem
            minH="48px"
            p="12px"
            gap="8px"
            borderBottom="1px solid"
            borderColor="whiteOpacity.12"
            onClick={() => toggleShowTxHistory()}
          >
            <HStack width="100%" alignItems="center">
              <Icon
                name="trxHistory"
                style={{ width: '20px', height: '20px' }}
              />
              <Text color="whiteOpacity.50" fontSize={14}>
                Transaction history
              </Text>
            </HStack>
          </MenuItem>
          <MenuItem
            minH="48px"
            p="12px"
            gap="8px"
            borderBottom="1px solid"
            borderColor="whiteOpacity.12"
            onClick={() => disconnect()}
          >
            <HStack width="100%" alignItems="center">
              <Icon
                name="disconnect"
                style={{ width: '20px', height: '20px' }}
              />
              <Text color="#FF0000" fontSize={14}>
                Disconnect
              </Text>
            </HStack>
          </MenuItem>
        </MenuList>
      </Menu>
      <AnimatePresence>
        {showTxHistory && (
          <motion.div
            style={{
              width: isMobile ? '100vw' : '512px',
              position: 'fixed',
              right: 0,
              top: 0,
              bottom: 0,
              zIndex: 110
            }}
            ref={menuRef}
            transition={{
              duration: 0.5,
              ease: [0.2, 0.71, 0.2, 1.01]
            }}
            initial={{
              transform: 'translateX(100%)'
            }}
            animate={{
              transform: 'translateX(0)'
            }}
            exit={{
              transform: 'translateX(100%)'
            }}
          >
            <TransactionHistorySelector onClose={toggleShowTxHistory} />
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

import { Box, HStack, Text, VStack } from '@chakra-ui/react';
import cn from 'clsx';
import Link from 'next/link';
import React from 'react';

import AppLogo from '@app/assets/images/logo/appLogo.svg';
import { footerNav } from '@app/components/AppFooter/options';
import { Icon } from '@app/components/Icon';
import { toOptionalFixed } from '@app/helpers/format';

import s from './AppFooter.module.scss';
import { RpcSelector } from '@app/components/RpcSelector';
import { useTotalAmmTvl } from '@app/hooks/other/useTotalAmmTvl';
import { useChainId } from '@thirdweb-dev/react';

export const AppFooter: React.FC = () => {
  const { data: totalAmmTvl, isLoading: isLoadingTotalTvl } = useTotalAmmTvl();

  const chainId = useChainId();

  return (
    <Box px="20px" mt="64px">
      <Box className={s.root}>
        <VStack align="flex-start">
          <AppLogo height="32px" />
          <Text mt="8px" fontSize="14px" color="whiteOpacity.50">
            {/* This is secondary text to give better context of the brand */}
          </Text>
          <HStack mt="auto">
            <Link
              href="https://x.com/kimprotocol"
              target="_blank"
              className={s.socialLink}
            >
              <Icon name="socialTwitter" />
            </Link>
            <Link
              href="https://discord.com/invite/kimprotocol"
              target="_blank"
              className={s.socialLink}
            >
              <Icon name="socialDiscord" />
            </Link>
            <Link
              href="https://mirror.xyz/kim-exchange.eth"
              target="_blank"
              className={s.socialLink}
            >
              <Icon name="socialWebsite" />
            </Link>
            {totalAmmTvl && (
              <Box className={s.socialInfo}>
                <Text fontSize="16px" color="whiteOpacity.50">
                  TVL - ${totalAmmTvl ? toOptionalFixed(totalAmmTvl, 2) : 'n/a'}
                </Text>
              </Box>
            )}
          </HStack>
        </VStack>
        <div className={s.nav}>
          {footerNav(chainId).map(topic => {
            return (
              <Box key={topic.title}>
                <h4 className={s.topicTitle}>{topic.title}</h4>
                <ul className={s.list}>
                  {topic.options
                    .filter(option => !!option.link)
                    .map((option, i) => {
                      return (
                        <li
                          className={cn(s.listItem, {
                            [s.disabled]: !option.link
                          })}
                          key={i}
                        >
                          {option.isExternal ? (
                            <Link
                              href={option.link}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {option.label}
                            </Link>
                          ) : (
                            <Link href={option.link}>{option.label}</Link>
                          )}
                        </li>
                      );
                    })}
                </ul>
              </Box>
            );
          })}
          <Box width="100%" sx={{ display: 'flex', alignItems: 'flex-end' }}>
            <RpcSelector />
          </Box>
        </div>
      </Box>
    </Box>
  );
};

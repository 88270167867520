import styled from '@emotion/styled';

export const Container = styled.div`
  //background: var(--brand-black);
  //height: var(--app-header-height);
  left: 0;
  position: sticky;
  top: 0;
  width: 100%;
  margin: 0 auto;
  z-index: 100;
  padding: 0;
`;

export const ContentWrapper = styled.div`
  margin: 0 auto;
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: flex-start;
  position: relative;
  padding: 18px 20px;
  gap: 16px;
  border: 1px solid var(--white-opacity-10);
  border-radius: 0;
  background: var(--gray-1);
  border-top: 0;
`;

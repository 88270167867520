import { Box, VStack, Text } from '@chakra-ui/react';
import React from 'react';

import AppLogo from '@app/assets/images/logo/appLogo.svg';

export const ConnectWalletWelcomeScreen = () => {
  return (
    <Box p="24px" bg="gray.8" h="100%">
      <VStack alignItems="flex-start" justifyContent="space-between" h="100%">
        <AppLogo height="31px" />
        <VStack alignItems="flex-start">
          <Text
            fontSize="20px"
            fontWeight="500"
            lineHeight="26px"
            color="white"
            mb="8px"
          >
            Connect Wallet
          </Text>
          <Text
            fontSize="16px"
            fontWeight="400"
            lineHeight="24px"
            color="whiteOpacity.50"
          >
            Select your wallet from the options to get started.
          </Text>
        </VStack>
      </VStack>
    </Box>
  );
};

import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { QueryKeys } from 'src/constants/queryKeys';

import { gql, request } from 'graphql-request';
import { useConfig } from '@app/config';

type QueryParams = {
  id: string | undefined;
};

const fetcher = async (
  baseUrl: string,
  params: QueryParams = {
    id: ''
  }
) => {
  if (!params.id) {
    return null;
  }

  const res = await request<{ bundles: { ethPrice: string }[] }>(
    `${baseUrl}`,
    gql`
      query {
        bundles {
          ethPrice
        }
      }
    `
  );

  return res.bundles && res.bundles.length ? res.bundles[0].ethPrice : '0';
};

export const useTokenPriceV3 = (
  params: QueryParams,
  options: UseQueryOptions<string | null> = {}
) => {
  const config = useConfig();

  return useQuery<string | null>(
    [QueryKeys.TOKEN_PRICE, { params }],
    async () => {
      return fetcher(config?.URLS.subgraphUrl as string, {
        ...params
      });
    },
    {
      enabled: !!(params.id && config),
      refetchOnWindowFocus: false,
      staleTime: 5000,
      ...options
    }
  );
};

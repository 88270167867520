import { useMutation } from '@tanstack/react-query';
import axios, { AxiosResponse } from 'axios';
import { getConfig, useConfig } from '@app/config';
import { ApplicationConfig } from '@app/config/types';

type SubmitProofPayload = {
  id: string;
  name: string;
  referralCode: string;
};

type SubmitProofResponse = {
  id: string;
  referralCode: string;
  invitedBy: string;
  createdAt: string;
  updatedAt: string;
};
async function submitProof(
  proof: string,
  wallet: string,
  referralCode?: string,
  config?: ApplicationConfig
) {
  const body = referralCode
    ? {
        referralCode
      }
    : {};
  const { data } = await axios.post<
    SubmitProofPayload,
    AxiosResponse<SubmitProofResponse>
  >(`${config?.URLS.middlewareUrl}/wallets`, body, {
    withCredentials: true,
    headers: {
      'x-auth-signature': proof,
      'x-auth-wallet': wallet
    }
  });

  return data;
}

type ChallengeResponse = {
  id: string;
  challenge: string;
};

export async function generateChallenge(
  wallet: string,
  config: ApplicationConfig | undefined
): Promise<string> {
  const { data } = await axios.post<void, AxiosResponse<ChallengeResponse>>(
    `${config?.URLS.middlewareUrl}/challenge`,
    {},
    {
      withCredentials: true,
      headers: {
        'x-auth-wallet': wallet
      }
    }
  );

  return data.challenge;
}

async function requestSign(params: string[]) {
  // @ts-ignore
  return await window.ethereum.request({
    method: 'personal_sign',
    params
  });
}

async function getProof(account: string, challenge: string) {
  try {
    return await requestSign([account, challenge]);
  } catch (e) {
    // Coinbase wallet case - params array must have different order
    return await requestSign([challenge, account]);
  }
}

async function mutation({
  address,
  referralCode,
  chainId
}: {
  address: string | undefined;
  referralCode?: string;
  chainId: number | undefined;
}) {
  try {
    // @ts-ignore
    if (!window.ethereum || !address) {
      return;
    }

    const config = getConfig(chainId);

    // @ts-ignore
    await window.ethereum.request({
      method: 'eth_requestAccounts'
    });

    const challenge = await generateChallenge(address, config);
    const proof = await getProof(address, challenge);

    await submitProof(proof, address, referralCode, config);

    return true;
  } catch (error) {
    console.error(error, 'Failed to login');
    // todo - handle error in case KimActivityValidationError

    return;
  }
}

export function useLogin() {
  return useMutation({
    mutationFn: mutation
  });
}

import { useFactoryDataV3 } from '@app/hooks/pools/useFactoryDataV3';
import { useMemo } from 'react';
import { useTotalTvl } from '@app/hooks/liquidity/useTotalTvl';
import { useVaults } from '@app/hooks/plugins/useVaults';
import { useConfig } from '@app/config';

export function useTotalAmmTvl() {
  const config = useConfig();
  const { data, isLoading: isLoadingV4 } = useFactoryDataV3({
    id: config?.ALGEBRA_ADDRESSES.ALGEBRA_FACTORY
  });
  const { tvl, isLoading: isLoadingV2 } = useTotalTvl();
  const { data: vaults } = useVaults();

  const total = useMemo(() => {
    const vaultsTvl =
      vaults?.reduce((acc, vault) => {
        return acc + vault.tvl;
      }, 0) ?? 0;

    return (
      (data?.totalValueLockedUSD ? Number(data.totalValueLockedUSD) : 0) +
      (tvl ?? 0) +
      vaultsTvl
    );
  }, [data?.totalValueLockedUSD, tvl, vaults]);

  return {
    data: total,
    isLoading: isLoadingV2 || isLoadingV4
  };
}

import { Heading } from '@chakra-ui/react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { FC } from 'react';

interface Props {
  link: string;
  title: string;
}

export const AppHeaderLink: FC<Props> = ({ link, title }) => {
  const router = useRouter();

  const isActive = router.pathname.includes(link);

  return (
    <Link href={link}>
      <Heading
        as="h3"
        px="10px"
        py="8px"
        bg={isActive ? 'whiteOpacity.4' : 'transparent'}
        borderRadius={[0, '10px']}
        border="1px solid transparent"
        borderColor={isActive ? 'whiteOpacity.6' : 'transparent'}
        minWidth="92px"
        fontSize="16px"
        textAlign="center"
        transition="all 0.2s ease"
        _hover={{ color: 'white' }}
        fontWeight="400"
        color={isActive ? 'orange' : 'whiteOpacity.50'}
      >
        {title}
      </Heading>
    </Link>
  );
};

export default AppHeaderLink;

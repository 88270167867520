import process from 'process';
import { ModeEnvs } from '@app/constants/chains';
import { Base, Mode, ModeTestnet } from '@thirdweb-dev/chains';
import { modeMainnetConfig } from '@app/config/modeMainnetConfig';
import { modeTestnetConfig } from '@app/config/modeTestnetConfig';
import { baseMainnetConfig } from '@app/config/baseMainnetConfig';
import { ApplicationConfig } from '@app/config/types';
import { useChainId } from '@thirdweb-dev/react';

export const __config = {
  modeEnv: (process.env.NEXT_PUBLIC_MODE_ENV ?? 'TESTNET') as ModeEnvs,
  tokensWhitelist: process.env.NEXT_PUBLIC_WHITELIST_TOKENS,
  useWhiteListTokens: process.env.NEXT_PUBLIC_USE_WHITELIST_TOKENS === 'true',
  showPointsBadge: process.env.NEXT_PUBLIC_SHOW_POINTS_BADGE === 'true',
  showLeaderboardPage: process.env.NEXT_PUBLIC_SHOW_LEADEBOARD_PAGE,
  showReferralsPage: process.env.NEXT_PUBLIC_SHOW_REFERRALS_PAGE === 'true',
  showRyoPages: process.env.NEXT_PUBLIC_SHOW_RYO_PAGES,
  showInfoPage: process.env.NEXT_PUBLIC_SHOW_INFO_PAGE === 'true',
  showNitroPage: process.env.NEXT_PUBLIC_SHOW_NITRO_PAGE === 'true',
  showBoosterPage: process.env.NEXT_PUBLIC_SHOW_BOOSTER_PAGE === 'true',
  showRewardsPage: process.env.NEXT_PUBLIC_SHOW_REWARDS_PAGE === 'true',
  showPartnersPage: process.env.NEXT_PUBLIC_SHOW_PARTNERS_PAGE === 'true',
  showStake: process.env.NEXT_PUBLIC_SHOW_STAKE,
  showSpNft: process.env.NEXT_PUBLIC_SHOW_SP_NFT_TAB,
  showAnnouncement: process.env.NEXT_PUBLIC_SHOW_ANNOUNCEMENT === 'true',
  showAirdropClaim: process.env.NEXT_PUBLIC_SHOW_AIDROP_CLAIM === 'true',
  thirdwebClientId: process.env.NEXT_PUBLIC_THIRDWEB_CLIENT_ID,
  subgraphUrl: process.env.NEXT_PUBLIC_SUBGRAPH_URL,
  middlewareUrl: process.env.NEXT_PUBLIC_KIZUNA_MIDDLEWARE_URL,
  explorerApiUrl: process.env.NEXT_PUBLIC_EXPLORER_API_URL,
  publicSaleLink: process.env.NEXT_PUBLIC_SALE_LINK,
  showBadges: process.env.NEXT_PUBLIC_SHOW_BADGES
    ? JSON.parse(process.env.NEXT_PUBLIC_SHOW_BADGES ?? '')
    : [],
  poolsOrder: process.env.NEXT_PUBLIC_POOLS_ORDER
    ? JSON.parse(process.env.NEXT_PUBLIC_POOLS_ORDER)
    : [],
  hiddenPools: process.env.NEXT_PUBLIC_HIDDEN_POOLS
    ? JSON.parse(process.env.NEXT_PUBLIC_HIDDEN_POOLS)
    : [],
  contracts: {
    factory: process.env.NEXT_PUBLIC_KIZUNA_FACTORY,
    router: process.env.NEXT_PUBLIC_KIZUNA_ROUTER,
    weth: process.env.NEXT_PUBLIC_WETH_CONTRACT ?? '',
    kim: process.env.NEXT_PUBLIC_KIZUNA_TOKEN,
    xKim: process.env.NEXT_PUBLIC_X_KIZUNA_TOKEN,
    dividends: process.env.NEXT_PUBLIC_DIVIDENDS,
    nftPoolFactory: process.env.NEXT_PUBLIC_NFT_POOL_FACTORY,
    yieldBooster: process.env.NEXT_PUBLIC_YIELD_BOOSTER,
    nitroPoolFactory: process.env.NEXT_PUBLIC_NITRO_POOL_FACTORY
  },
  defaultTokens: {
    from: process.env.NEXT_PUBLIC_DEFAULT_FROM_TOKEN_ADDRESS ?? '',
    to: process.env.NEXT_PUBLIC_DEFAULT_TO_TOKEN_ADDRESS ?? ''
  },
  subgraphUrlV3: process.env.NEXT_PUBLIC_SUBGRAPH_URL_V3,
  subgraphUrlSteer: process.env.NEXT_PUBLIC_SUBGRAPH_URL_STEER,
  middlewareUrlV3: process.env.NEXT_PUBLIC_MIDDLEWARE_URL_V3,
  merklApiUrl: process.env.NEXT_PUBLIC_MERKL_API_URL,
  steerApiUrl: process.env.NEXT_PUBLIC_STEER_API_URL,
  showVaults: process.env.NEXT_PUBLIC_SHOW_VAULTS,
  subgraphUrlNitroPools: process.env.NEXT_PUBLIC_SUBGRAPH_URL_NITRO_POOLS
};

export const envConfig = {
  modeEnv: (process.env.NEXT_PUBLIC_MODE_ENV ?? 'TESTNET') as ModeEnvs,
  thirdwebClientId: process.env.NEXT_PUBLIC_THIRDWEB_CLIENT_ID,
  useBase: process.env.NEXT_PUBLIC_USE_BASE === 'true'
};

const APP_CONFIG: Record<number, ApplicationConfig> = {
  [Mode.chainId]: modeMainnetConfig,
  [ModeTestnet.chainId]: modeTestnetConfig,
  [Base.chainId]: baseMainnetConfig
};

export function getConfig(
  chainId: number | undefined
): ApplicationConfig | undefined {
  if (!chainId) {
    return;
  }

  return APP_CONFIG[chainId] ?? APP_CONFIG[Mode.chainId];
}

export function useConfig(_chainId?: number) {
  const chainId = useChainId();

  return getConfig(_chainId ?? chainId ?? Mode.chainId);
}

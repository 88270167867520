export const META_TAGS_DEFAULT_CONFIG = {
  twitterCardType: 'summary_large_image',
  twitterCreator: '@kimprotocol',
  twitterImageAlt: '',
  twitterSite: '@kimprotocol',
  title: 'Kim Exchange',
  description: 'Swap or provide liquidity on Kim Exchange',
  url: 'https://app.kim.exchange',
  image: 'https://app.kim.exchange/assets/images/banner.png',
  logo: 'https://app.kim.exchange/assets/images/logo.png'
};

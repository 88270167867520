import { ModeTestnet, Mode } from '@thirdweb-dev/chains';
import { envConfig } from '@app/config';

export enum ModeEnvs {
  MAINNET = 'MAINNET',
  TESTNET = 'TESTNET'
}

export const RPC_OVERRIDE_KEY = 'KIM_RPC_OVERRIDE';

function getActiveRpc(defaultRpc: readonly string[]) {
  try {
    const rpcOverride = localStorage?.getItem(RPC_OVERRIDE_KEY);

    if (rpcOverride) {
      const rpc = JSON.parse(rpcOverride);

      if (rpc && Array.isArray(rpc.value) && rpc.value.length > 0) {
        return rpc.value;
      }
    }

    return defaultRpc;
  } catch (e) {
    return defaultRpc;
  }
}

export function getActiveChain() {
  switch (envConfig.modeEnv) {
    case ModeEnvs.MAINNET: {
      return { ...Mode, rpc: getActiveRpc(Mode.rpc) };
    }
    case ModeEnvs.TESTNET:
    default: {
      return { ...ModeTestnet, rpc: getActiveRpc(ModeTestnet.rpc) };
    }
  }
}

import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from '@app/constants/queryKeys';
import { generateChallenge } from '@app/hooks/user/useLogin';
import { useAddress } from '@thirdweb-dev/react';

async function fetcher(address: string | undefined) {
  if (!address) {
    return {
      isEligible: false,
      error: null
    };
  }

  try {
    // const challenge = await generateChallenge(address);

    return {
      isEligible: false,
      error: null
    };
  } catch (e: any) {
    if (e?.response?.data?.name === 'KimActivityValidationError') {
      return {
        isEligible: false,
        error: 'KimActivityValidationError'
      };
    }

    return {
      isEligible: false,
      error: ''
    };
  }
}

export function useEligibleToReferral() {
  const address = useAddress();

  return useQuery([QueryKeys.ELIGIBLE_TO_REFERRAL, address], () =>
    fetcher(address)
  );
}

import React, { FC } from 'react';
import {
  Flex,
  HStack,
  IconButton,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text
} from '@chakra-ui/react';
import s from './TransactionHistorySelector.module.scss';
import { TransactionsHistoryV4 } from '@app/components/TransactionsHistory/TransactionsHistoryV4';
import { TransactionsHistory } from '@app/components/TransactionsHistory';
import { Icon } from '@app/components/Icon';

interface Props {
  onClose: (nxtVal?: boolean) => void;
}

export const TransactionHistorySelector: FC<Props> = ({ onClose }) => {
  return (
    <Flex
      sx={{
        background: 'gray.9',
        width: '100%',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <HStack
        justifyContent="space-between"
        alignItems="center"
        mb="24px"
        p="24px 24px 0"
      >
        <Text sx={{ fontSize: '28px', fontWeight: 500, color: 'white' }}>
          Transaction History
        </Text>
        <IconButton
          aria-label="close"
          onClick={() => onClose()}
          sx={{
            borderRadius: '4px',
            border: '1px solid',
            borderColor: 'whiteOpacity.6',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '11px 10px'
          }}
        >
          <Icon name="close" />
        </IconButton>
      </HStack>
      <Tabs>
        <TabList className={s.tabs}>
          <Tab className={s.tab}>V4</Tab>
          <Tab className={s.tab}>V2</Tab>
        </TabList>
        <TabIndicator mt="-3px" height="3px" bg="orange" borderRadius="1px" />
        <TabPanels>
          <TabPanel mt={6}>
            <TransactionsHistoryV4 onClose={onClose} />
          </TabPanel>
          <TabPanel mt={6}>
            <TransactionsHistory onClose={onClose} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  );
};

import Link from 'next/link';
import React, { FC, PropsWithChildren } from 'react';
import { useMedia } from 'react-use';
import LogoIcon from '@app/assets/images/logo/logoIcon.svg';
import { Account } from '@app/components/AppHeader/components/Account';
import { Container, ContentWrapper } from '@app/components/AppHeader/styled';

import s from './AppHeader.module.scss';
import { LocationWarningBanner } from '@app/components/LocationWarningBanner';
import { Socials } from '@app/components/AppHeader/components/Socials';
import { NetworkSelector } from '@app/components/NetworkSelector';
import { HStack, Center, Text } from '@chakra-ui/react';
import { useConfig } from '@app/config';

export const AppHeader: FC<PropsWithChildren> = ({ children }) => {
  const isMobile = useMedia('(max-width: 992px)', true);
  const config = useConfig();

  return (
    <Container className={s.root}>
      <LocationWarningBanner />
      {config?.FLAGS.showAnnouncement && (
        <Center
          sx={{
            width: '100%',
            backgroundColor: 'bg.accent',
            padding: '2px 4px'
          }}
        >
          <Text
            fontSize={['12px', '18px']}
            color="primaryWhite"
            fontWeight={500}
            margin={0}
            textAlign="center"
          >
            <Link
              href="https://app.hedgey.finance/investor-lockups"
              target="_blank"
              rel="noreferrer"
            >
              Public Sale Tokens Click Here!
            </Link>
          </Text>
        </Center>
      )}
      <ContentWrapper className={s.wrapper}>
        {!isMobile && (
          <>
            <Link href="/">
              <LogoIcon height="32px" />
            </Link>
          </>
        )}
        {isMobile ? (
          <>
            <Link href="/">
              <HStack>
                <LogoIcon height="26px" width="26px" />
              </HStack>
            </Link>
            {/*<MobileMenu>*/}
            {/*  <>{children}</>*/}
            {/*</MobileMenu>*/}
            <HStack ml="auto">
              <NetworkSelector />
              <Account />
            </HStack>
          </>
        ) : (
          <>
            <HStack justifyContent="center" alignItems="center" flex="1">
              {children}
            </HStack>
            <Socials />
            <NetworkSelector />
            <Account />
          </>
        )}
      </ContentWrapper>
    </Container>
  );
};

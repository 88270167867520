import { ModeEnvs } from '@app/constants/chains';
import { ApplicationConfig } from '@app/config/types';
import { Token } from '@app/types/token';

export const modeMainnetConfig: ApplicationConfig = {
  modeEnv: (process.env.NEXT_PUBLIC_MODE_ENV ?? 'TESTNET') as ModeEnvs,
  thirdwebClientId: process.env.NEXT_PUBLIC_THIRDWEB_CLIENT_ID ?? '',
  CONTENT: {
    tokensWhitelist: process.env.NEXT_PUBLIC_WHITELIST_TOKENS
      ? (JSON.parse(
          process.env.NEXT_PUBLIC_WHITELIST_TOKENS
        ) as unknown as Token[])
      : [],
    useWhiteListTokens: process.env.NEXT_PUBLIC_USE_WHITELIST_TOKENS === 'true',
    publicSaleLink: process.env.NEXT_PUBLIC_SALE_LINK ?? '',
    poolsOrder: process.env.NEXT_PUBLIC_POOLS_ORDER
      ? JSON.parse(process.env.NEXT_PUBLIC_POOLS_ORDER)
      : [],
    hiddenPools: process.env.NEXT_PUBLIC_HIDDEN_POOLS
      ? JSON.parse(process.env.NEXT_PUBLIC_HIDDEN_POOLS)
      : [],
    defaultTokens: {
      from: process.env.NEXT_PUBLIC_DEFAULT_FROM_TOKEN_ADDRESS ?? '',
      to: process.env.NEXT_PUBLIC_DEFAULT_TO_TOKEN_ADDRESS ?? ''
    }
  },
  ALGEBRA_ADDRESSES: {
    POOL_INIT_CODE_HASH:
      '0xf96d2474815c32e070cd63233f06af5413efc5dcb430aee4ff18cc29007c562d',
    ALGEBRA_FACTORY: '0xB5F00c2C5f8821155D8ed27E31932CFD9DB3C5D5',
    ALGEBRA_POOL_DEPLOYER: '0x6414A461B19726410E52488d9D5ff33682701635',
    ALGEBRA_POSITION_MANAGER: '0x2e8614625226D26180aDf6530C3b1677d3D7cf10',
    ALGEBRA_QUOTER: '0x8678f0aBe08D30dC555F27c488551569A29B31Eb',
    ALGEBRA_QUOTER_V2: '0x7C5AaA464f736740156fD69171505D344855d1e5',
    ALGEBRA_ROUTER: '0xAc48FcF1049668B285f3dC72483DF5Ae2162f7e8'
  },
  MERKL: {
    DISTRIBUTOR: '0x3Ef3D8bA38EBe18DB133cEc108f4D14CE00Dd9Ae'
  },
  AIRDROP_CONTRACTS: {
    TGE: {
      KIM: '0x13fB30f4c83d63Fb7549e3D57e64b63Ba4b11E6f',
      X_KIM: '0x13fB30f4c83d63Fb7549e3D57e64b63Ba4b11E6f'
    },
    AIRDROP_2: {
      KIM: '0xec09B976322042d114C1E83DCaeE61A2a978Bee1',
      X_KIM: '0xec09B976322042d114C1E83DCaeE61A2a978Bee1'
    },
    AIRDROP_3: {
      KIM: '0xA7d1f2e48eafe16B1CFD840C860700Fd9a4C7135',
      X_KIM: '0xA7d1f2e48eafe16B1CFD840C860700Fd9a4C7135'
    },
    AIRDROP_4: {
      KIM: '0xd218d620D384335d28C761a9e1dF9Ceafeac0224',
      X_KIM: '0xd218d620D384335d28C761a9e1dF9Ceafeac0224'
    },
    AIRDROP_5: {
      KIM: '0xD44bFaF8a1d21e7DbA1802dD35084a3b9e6b8566',
      X_KIM: '0xD44bFaF8a1d21e7DbA1802dD35084a3b9e6b8566'
    }
  },
  AIRDROP_TOKENS: {
    KIM: '0x6863fb62Ed27A9DdF458105B507C15b5d741d62e',
    X_KIM: '0x4D850FE01F07BEd416558A34dbde88bA60aE19BE'
  },
  STEER: {
    PROXY_HELPER: '0xc0E93CE4857F168E6eE790800C68dd701cC41B37'
  },
  ICHI: {
    PROXY_HELPER: '0x5918Ce227F08Caa71319b562fFcf2Ab4d57C22b4'
  },
  CONTRACTS: {
    KIM_MASTER: '0x989ef16f9C98C48CCC615658AFDFea9dD55A4Ae9',
    FACTORY: process.env.NEXT_PUBLIC_KIZUNA_FACTORY ?? '',
    ROUTER: process.env.NEXT_PUBLIC_KIZUNA_ROUTER ?? '',
    WETH: process.env.NEXT_PUBLIC_WETH_CONTRACT ?? '',
    KIM: process.env.NEXT_PUBLIC_KIZUNA_TOKEN ?? '',
    X_KIM: process.env.NEXT_PUBLIC_X_KIZUNA_TOKEN ?? '',
    DIVIDENDS: process.env.NEXT_PUBLIC_DIVIDENDS ?? '',
    NFT_POOL_FACTORY: process.env.NEXT_PUBLIC_NFT_POOL_FACTORY ?? '',
    YIELD_BOOSTER: process.env.NEXT_PUBLIC_YIELD_BOOSTER ?? '',
    NITRO_POOL_FACTORY: process.env.NEXT_PUBLIC_NITRO_POOL_FACTORY ?? ''
  },
  FLAGS: {
    showPointsBadge: process.env.NEXT_PUBLIC_SHOW_POINTS_BADGE === 'true',
    showLeaderboardPage:
      process.env.NEXT_PUBLIC_SHOW_LEADEBOARD_PAGE === 'true',
    showReferralsPage: process.env.NEXT_PUBLIC_SHOW_REFERRALS_PAGE === 'true',
    showRyoPages: process.env.NEXT_PUBLIC_SHOW_RYO_PAGES === 'true',
    showInfoPage: process.env.NEXT_PUBLIC_SHOW_INFO_PAGE === 'true',
    showNitroPage: process.env.NEXT_PUBLIC_SHOW_NITRO_PAGE === 'true',
    showBoosterPage: process.env.NEXT_PUBLIC_SHOW_BOOSTER_PAGE === 'true',
    showRewardsPage: process.env.NEXT_PUBLIC_SHOW_REWARDS_PAGE === 'true',
    showPartnersPage: process.env.NEXT_PUBLIC_SHOW_PARTNERS_PAGE === 'true',
    showStake: process.env.NEXT_PUBLIC_SHOW_STAKE === 'true',
    showSpNft: process.env.NEXT_PUBLIC_SHOW_SP_NFT_TAB === 'true',
    showAnnouncement: process.env.NEXT_PUBLIC_SHOW_ANNOUNCEMENT === 'true',
    showAirdropClaim: process.env.NEXT_PUBLIC_SHOW_AIDROP_CLAIM === 'true',
    showBadges: process.env.NEXT_PUBLIC_SHOW_BADGES
      ? JSON.parse(process.env.NEXT_PUBLIC_SHOW_BADGES ?? '')
      : [],
    showVaults: process.env.NEXT_PUBLIC_SHOW_VAULTS === 'true',
    showRouterIntentPage: process.env.NEXT_PUBLIC_SHOW_ROUTER_INTENT === 'true',
    showDosirakPage: true
  },
  URLS: {
    subgraphUrl: process.env.NEXT_PUBLIC_SUBGRAPH_URL ?? '',
    middlewareUrl: process.env.NEXT_PUBLIC_KIZUNA_MIDDLEWARE_URL ?? '',
    explorerApiUrl: process.env.NEXT_PUBLIC_EXPLORER_API_URL ?? '',
    subgraphUrlV3: process.env.NEXT_PUBLIC_SUBGRAPH_URL_V3 ?? '',
    subgraphUrlSteer: process.env.NEXT_PUBLIC_SUBGRAPH_URL_STEER ?? '',
    middlewareUrlV3: process.env.NEXT_PUBLIC_MIDDLEWARE_URL_V3 ?? '',
    merklApiUrl: process.env.NEXT_PUBLIC_MERKL_API_URL ?? '',
    steerApiUrl: process.env.NEXT_PUBLIC_STEER_API_URL ?? '',
    subgraphUrlNitroPools:
      process.env.NEXT_PUBLIC_SUBGRAPH_URL_NITRO_POOLS ?? '',
    routerIntent: process.env.NEXT_PUBLIC_ROUTER_INTENT_API_URL
  }
};

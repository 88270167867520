import React from 'react';
import Link from 'next/link';
import { HStack } from '@chakra-ui/react';
import { Icon } from '@app/components/Icon';

import s from './Sosials.module.scss';

export const Socials = () => {
  return (
    <HStack gap={4} alignItems="center">
      <Link
        href="https://docs.kim.exchange/"
        target="_blank"
        className={s.socialLink}
      >
        <Icon name="bookLink" />
      </Link>
      <Link
        href="https://x.com/kimprotocol"
        target="_blank"
        className={s.socialLink}
      >
        <Icon name="socialTwitter" />
      </Link>
    </HStack>
  );
};

import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { request, gql, Variables } from 'graphql-request';

import { QueryKeys } from 'src/constants/queryKeys';

import { UniswapFactory } from '@app/types/pool';
import { useConfig } from '@app/config';
import { useChainId } from '@thirdweb-dev/react';
import { useSupportedChain } from '@app/hooks/other/useSupportedChain';

const fetcher = (
  baseUrl: string,
  params: Variables = {
    id: ''
  }
) => {
  return request<{ uniswapFactory: UniswapFactory }>(
    `${baseUrl}`,
    gql`
      query {
        uniswapFactory(id: "${params.id}") {
          id,
          totalVolumeUSD,
          totalLiquidityUSD,
          totalLiquidityETH,
          totalFeeUSD,
          txCount,
          pairCount,
          totalVolumeETH
        }
      }
    `,
    params
  );
};

export const useFactoryData = (
  params: Variables = {},
  options: UseQueryOptions<UniswapFactory> = {}
) => {
  const config = useConfig();
  const chainId = useSupportedChain()?.chainId;

  return useQuery<UniswapFactory>(
    [QueryKeys.FACTORY_DATA, { params }, chainId],
    async () => {
      const response = await fetcher(config?.URLS.subgraphUrl as string, {
        ...params
      });

      return response.uniswapFactory;
    },
    {
      staleTime: 60000,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: !!config,
      ...options
    }
  );
};

import { Mode } from '@thirdweb-dev/chains';
import { create } from 'zustand';

interface ChainState {
  readonly activeChainId: number;
  actions: {
    setActiveChainId: (id: number) => void;
  };
}

export const useChainState = create<ChainState>(setState => ({
  activeChainId: Mode.chainId,
  actions: {
    setActiveChainId: (id: number) => setState({ activeChainId: id })
  }
}));

import React, { useState } from 'react';
import { Box, Button, Text } from '@chakra-ui/react';
import Countdown from 'react-countdown';
import { APP_ROUTES } from '@app/constants/routes';
import { useRouter } from 'next/router';
import { useAirdropDetails } from '@app/hooks/airdrop/useAirdropDetails';
import { useConfig } from '@app/config';

function renderDayToken(d: number) {
  return d < 10 ? `0${d}` : d;
}

export const AirdropClaimBanner = ({ type = 'normal' }: { type?: string }) => {
  const router = useRouter();
  const config = useConfig();
  const [isCopyFrom, setIsCopyFrom] = useState(false);

  const { data: airdropDetails } = useAirdropDetails('TGE', true);

  if (!airdropDetails) {
    return null;
  }

  if (isCopyFrom) {
    setTimeout(() => {
      setIsCopyFrom(false);
    }, 2500);
  }

  if (!config?.FLAGS.showAirdropClaim) {
    return null;
  }

  return (
    <Box sx={{ position: 'relative' }} width="100%">
      <Button
        sx={{
          borderRadius: '8px',
          border: '1px solid',
          borderColor: 'orange',
          fontSize: 16,
          color: 'orange',
          background: 'secondary.darkOrange',
          py: '7px',
          width: type === 'normal' ? '100%' : undefined
        }}
        variant="primary"
        py="3px"
        px={type === 'normal' ? '12px' : 24}
        onClick={() => {
          router.push(APP_ROUTES.airdrop);
        }}
      >
        <Countdown
          date={new Date(airdropDetails.from)}
          renderer={({ days, hours, minutes, seconds, completed }) => {
            if (completed) {
              return (
                <Text fontSize="18px" fontWeight={400} color="orange">
                  Airdrop
                </Text>
              );
            } else {
              return (
                <>
                  <Text fontSize="16px" fontWeight={400} color="orange">
                    Airdrop in:&nbsp;
                    <span
                      style={{
                        width: '112px',
                        textAlign: 'left',
                        display: 'inline-block'
                      }}
                    >
                      {renderDayToken(days)}&nbsp;:&nbsp;{renderDayToken(hours)}
                      &nbsp;:&nbsp;{renderDayToken(minutes)}
                      &nbsp;:&nbsp;{renderDayToken(seconds)}
                    </span>
                  </Text>
                </>
              );
            }
          }}
        />
      </Button>
    </Box>
  );
};
